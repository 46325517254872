/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Input,
  // Segment,
  // List,
  // Image,
  // Icon,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaEnvelopeOpenText, FaEnvelope } from 'react-icons/fa'
import { FaFileCircleExclamation } from 'react-icons/fa6'

import { getAllTabulationsRequest } from '~/store/modules/tabulacoes/actions'
import {
  changeTicketsTypeRequest,
  showTicketsRequest,
  changeTicketsUserRequest,
} from '~/store/modules/tickets/actions'

import { Container, Menu, ListSemantic } from './styles'

const statusString = ['', 'aberto', 'fechado', 'pendente']
// const statusSemantic = ['', 'green', 'black', 'yellow']

function getTabulationName(tabulacoes, id) {
  // eslint-disable-next-line no-unused-vars
  for (const tabulacao of tabulacoes) {
    if (tabulacao.id === id) {
      return tabulacao.nome
    }

    if (tabulacao.tabulations.length > 0) {
      const tabulationName = getTabulationName(tabulacao.tabulations, id)
      if (tabulationName) {
        return tabulationName
      }
    }
  }
  return null
}

export default function Tickets() {
  const dispatch = useDispatch()
  const [proto, setProto] = useState('')
  const [de, setDe] = useState('')
  const [para, setPara] = useState('')
  let start = new Moment(new Date())
  start = new Moment(start.format('YYYY-MM-DD')).toDate()
  const [startDate, setStartDate] = useState(start)
  let final = new Moment(new Date())
  final = final.add(30, 'days')
  const [endDate, setEndDate] = useState(final.toDate())

  const { visualizacao, visualizacaoUser, tickets } = useSelector(
    (state) => state.tickets,
  )
  const { usuarios } = useSelector((state) => state.usuarios)
  const tabulacoes = useSelector((state) => state.tabulacoes)
  const profile = useSelector((state) => state.user.profile)

  useEffect(() => {
    dispatch(
      showTicketsRequest({
        proto,
        de,
        para,
        inicio: startDate,
        termino: endDate,
      }),
    )
  }, [de, dispatch, endDate, para, proto, startDate])

  useEffect(() => {
    dispatch(getAllTabulationsRequest({ domainId: profile.id_dominio }))
  }, [dispatch, profile])

  return (
    <Container>
      <div>
        <Menu>
          <p>Visualizações</p>
          <li className={visualizacao === 'todos' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('todos'))}
            >
              Todos
            </button>
          </li>
          <li className={visualizacao === 'abertos' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('abertos'))}
            >
              Abertos
            </button>
          </li>
          <li className={visualizacao === 'fechados' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('fechados'))}
            >
              Fechados
            </button>
          </li>
          <li className={visualizacao === 'pendentes' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('pendentes'))}
            >
              Pendentes
            </button>
          </li>
          <li className={visualizacao === 'meus_tickets' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('meus_tickets'))}
            >
              Meus Tickets
            </button>
          </li>
          <li className={visualizacao === 'deletados' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsTypeRequest('deletados'))}
            >
              Deletados
            </button>
          </li>
        </Menu>
        <Menu>
          <p>Usuários</p>
          <li className={visualizacaoUser === 'todos' ? 'ativo' : ''}>
            <button
              type="button"
              onClick={() => dispatch(changeTicketsUserRequest('todos'))}
            >
              Todos
            </button>
          </li>
          {usuarios.map((item) => {
            return (
              <li
                key={item.id}
                className={visualizacaoUser === item.id ? 'ativo' : ''}
              >
                <button
                  type="button"
                  onClick={() => dispatch(changeTicketsUserRequest(item.id))}
                >
                  {item.nome}
                </button>
              </li>
            )
          })}
        </Menu>
        <div className="Search_Control">
          <label htmlFor="protocolo">Protocolo</label>
          <Input
            id="protocolo"
            type="text"
            value={proto}
            onChange={(e) => setProto(e.target.value)}
          />
        </div>
        <div className="Search_Control">
          <label htmlFor="de">De</label>
          <Input
            id="de"
            type="text"
            value={de}
            onChange={(e) => setDe(e.target.value)}
          />
        </div>
        <div className="Search_Control">
          <label htmlFor="para">Para</label>
          <Input
            id="para"
            type="text"
            value={para}
            onChange={(e) => setPara(e.target.value)}
          />
        </div>
        <div className="Search_Control">
          <label htmlFor="start">Inicio</label>
          <div className="ui input">
            <DatePicker
              id="start"
              showPopperArrow={false}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm:ss"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="dd-MM-yyyy HH:mm:ss"
              className="input_date"
              withPortal
            />
          </div>
        </div>
        <div className="Search_Control">
          <label htmlFor="end">Termino</label>
          <div className="ui input">
            <DatePicker
              id="end"
              showPopperArrow={false}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              timeFormat="HH:mm:ss"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="dd-MM-yyyy HH:mm:ss"
              className="input_date"
              withPortal
            />
          </div>
        </div>
      </div>

      <ListSemantic>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                #
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Data
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Status
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Fechado Primeiro Contato
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Tabulação
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Originador
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                DID
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Agente
              </TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {tickets.rows.map((ticket) => {
              return (
                <TableRow key={ticket.id}>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    <Link to={`chamado/${ticket.id}`}>{ticket.id}</Link>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {Moment(ticket.inicio).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                    warning={ticket.aberto === 3}
                    negative={ticket.aberto === 1}
                    positive={ticket.aberto === 2}
                  >
                    {ticket.aberto === 3 && <FaFileCircleExclamation />}{' '}
                    {ticket.aberto === 2 && <FaEnvelope color="green" />}{' '}
                    {ticket.aberto === 1 && <FaEnvelopeOpenText color="red" />}{' '}
                    {statusString[ticket.aberto]}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {ticket.fechado_primeiro_contato === 1 ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {getTabulationName(
                      tabulacoes.tabulations,
                      ticket.fk_id_tabulacao,
                    )}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {ticket.de}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {ticket.para}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {ticket.usuario.nome}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ListSemantic>
    </Container>
  )
}

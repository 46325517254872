/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Input,
  Message,
  Button,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Label,
  Table,
} from 'semantic-ui-react'

import { getContactsRequest } from '~/store/modules/contatos/actions'
import { ContainerSemantic } from './styles'

export default function Contatos() {
  const dispatch = useDispatch()
  const contatos = useSelector((state) => state.contatos.contacts)
  const [ident, setIdent] = useState('')
  const [did, setDid] = useState('')

  useEffect(() => {
    dispatch(getContactsRequest({ ident, did }))
  }, [did, dispatch, ident])

  return (
    <ContainerSemantic>
      <Message>
        <Input
          id="did"
          type="text"
          value={did}
          placeholder="DID"
          onChange={(e) => setDid(e.target.value)}
        />
        <Input
          id="identificacao"
          type="text"
          value={ident}
          placeholder="Identificação"
          onChange={(e) => setIdent(e.target.value)}
        />
        <Link to="/contatos/add">
          <Button fluid primary>
            Adicionar
          </Button>
        </Link>
        <Link to="/contatos/template">
          <Button fluid color="green">
            Template
          </Button>
        </Link>
      </Message>
      <div className="ui cards">
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                #
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Numero
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Nome
              </TableHeaderCell>
              <TableHeaderCell
                style={{ textAlign: 'center', verticalAlign: 'middle' }}
              >
                Fraseologia
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {contatos.rows?.map((contato) => {
              console.log(contato)

              return (
                <TableRow key={contato.id}>
                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    <Link to={`/contato/${contato.id}`}>{contato.id}</Link>
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.ContactNumbers.map((number) => {
                      return (
                        <Label key={number.id} horizontal color="teal">
                          {number.numero}
                        </Label>
                      )
                    })}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.descricao}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                  >
                    {contato.fraseologia}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </ContainerSemantic>
  )
}
